@import url("https://fonts.googleapis.com/css2?family=Alice&family=DM+Sans&family=EB+Garamond&display=swap");
body {
  background-color: #000000;
}

hr {
  background-color: #cccccc;
}

.title {
  font-family: "EB Garamond", cursive;
  font-size: 3rem;
  color: #f5c559;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-shadow: -1px -1px 0 #88261c, 1px -1px 0 #88261c, -1px 1px 0 #88261c, 1px 1px 0 #88261c;
}

.logo {
  margin-right: 2rem;
}

.navBarBG {
  background-image: url(../images/GridBackground.svg);
  background-color: #000000;
  background-size: 50px;
}

.navLink {
  color: #cccccc;
  border-style: none;
}

.navLink:hover,
.navDropdown:hover {
  background-color: #181818;
}

#dropdown-basic-button {
  color: #cccccc;
  background-color: rgba(0, 0, 0, 0);
}

#dropdown-basic-button:hover {
  color: #cccccc;
  background-color: #181818;
}

#dropdown-basic-button:active {
  color: #cccccc;
  background-color: #181818;
}

.slogan {
  color: #cccccc;
  font-style: italic;
}

.headerBG {
  padding: 2rem 0;
  background-image: url(../images/SloganBackground.svg);
}

.generalWarning {
  color: #b40000;
  font-size: 1.5rem;
  font-family: serif;
}

.bookHeader {
  color: #f5c559;
  font-family: "Alice", serif;
  font-size: 1.5rem;
  font-style: italic;
  font-weight: bold;
}

.generalHeader {
  color: #f5c559;
  font-family: "DM Sans", sans-serif;
  font-size: 1.3rem;
}

.generalText {
  color: #cccccc;
  font-family: "DM Sans", sans-serif;
  word-wrap: break-word;
}

.smallText {
  font-size: 0.75rem;
}

.bookLink {
  color: #f5c559;
}

.bookImg {
  max-height: 100vh;
}

.blogHeader {
  color: #f5c559;
  font-family: "DM Sans", sans-serif;
  font-size: 1.3rem;
}

.blogDate {
  color: #cccccc;
  font-family: "DM Sans", sans-serif;
  font-size: 0.8rem;
  margin-bottom: 1rem;
}

.blogBody {
  color: #cccccc;
  font-family: "DM Sans", sans-serif;
}

.blogSnipHeader {
  color: #f5c559;
  font-family: "DM Sans", sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
}

.blogSnipBody {
  color: #cccccc;
  font-family: "DM Sans", sans-serif;
}

.underConstruction {
  color: #b40000;
  font-size: 1.1rem;
  font-family: serif;
}

.disabled {
  cursor: not-allowed;
}